<template>
  <v-lazy 
    transition="">
    <v-text-field
      v-if="item.isActive"
      flat
      solo
      hide-details
      :value="item[col]"
      :background-color="item.color"
      class="table-cell"
      :height="height"
      :readonly="isDisabled"
      :class="!this.$store.getters.isAdmin ? 'table-cell-placeholder-technik' : ''" 
      @change="
        (newVal) => {
          item[col] = newVal;
          rowChanged(item);
        }
      "
    ></v-text-field>
    <div 
      v-else
      class="table-cell-placeholder"
      :style="{backgroundColor: item.color}"
      :class="!this.$store.getters.isAdmin ? 'table-cell-placeholder-technik' : ''"
    > {{ item[col] }}</div>
  </v-lazy>
</template>

<script>

export default {
  name: "TextEditCell",

  props: ["item", "col", "height", "rowChanged", "isDisabled"],
};
</script>

<style>
</style>