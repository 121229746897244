<template>
  <v-lazy transition="">
    <v-combobox
      v-if="item.isActive"
      flat
      solo
      hide-details
      :value="item[col]"
      :items="items"
      :background-color="item.color"
      class="table-cell"
      :class="!this.$store.getters.isAdmin ? 'table-cell-placeholder-technik' : ''" 
      :height="height"
      :menu-props="{ offsetY: true }"
      :readonly="isDisabled"
      hide-no-data
      auto-select-first
      cache-items
      @change="
        (newVal) => {
          item[col] = newVal;
          rowChanged(item);
        }
      "
    ></v-combobox>

    <div v-else class="table-cell-placeholder" :class="!this.$store.getters.isAdmin ? 'table-cell-placeholder-technik' : ''"  :style="{background: item.color}" style="padding: 0px 12px 0px 12px !important;">
      <div class="v-select__slot">
        <div class="v-select__selections">
          <div class="v-select__selection v-select__selection--comma">
              {{ item[col] }}
          </div>
        </div>
        <div class="v-input__append-inner" style="padding: 3px 0px;">
          <div class="v-input__icon v-input__icon--append">
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-menu-down theme--light"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </v-lazy>
</template>
<script>
export default {
  name: "AutoCompleteEditCell",

  props: ["item", "col", "height", "rowChanged", "items", "isDisabled"],
};
</script>