<template>
  <div>
    <v-container class="container--fluid grid-list-md">
      <TableContainer/>
    </v-container>
  </div>
</template>

<script>
import TableContainer from '@/views/components/table/TableContainer'

export default {
    name: "Planovac",

    components:{
      TableContainer,
    },
}
</script>

<style>

</style>