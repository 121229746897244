<template>
	<div>
		<v-row dense>
			<DateNavigator />
		</v-row>
		<v-row dense v-if="open">
			<TempTableCard
				:link_props="link"
				:ws_data="linky_data_changed"
				v-for="link in linky"
				:key="link.table_name"
			/>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

import DateNavigator from "@/views/components/table/DateNavigator.vue"
import TempTableCard from "@/views/components/table/TempTableCard.vue"

export default {
	name: "TableContainerTemp",

	components: {
		TempTableCard,
		DateNavigator,
	},

	data() {
		return {
			open: false,
			linky_data_changed: "",
		}
	},

	computed: {
		...mapGetters(["linky", "socket", "isSuperAdmin"]),
	},

	methods: {},

	created() {
		this.socket.addEventListener("message", (event) => {
			const message = JSON.parse(event.data).message
			this.linky_data_changed = message
		})
	},

	activated() {
		this.open = true
	},
}
</script>

<style></style>
