export default {
	CASY: [
		"6:30",
		"6:45",
		"7:00",
		"7:15",
		"7:30",
		"7:45",
		"8:00",
		"8:15",
		"8:30",
		"8:45",
		"9:00",
		"9:15",
		"9:30",
		"9:45",
		"10:00",
		"10:15",
		"10:30",
		"10:45",
		"11:00",
		"11:15",
		"11:30",
		"11:45",
		"12:00",
		"12:15",
		"12:30",
		"12:45",
		"13:00",
		"13:15",
		"13:30",
		"13:45",
		"14:00",
		"14:15",
		"14:30",
		"14:45",
		"15:00",
		"15:15",
		"15:30",
		"15:45",
		"16:00",
		"16:15",
		"16:30",
		"16:45",
		"17:00",
		"17:15",
		"17:30",
		"17:45",
		"18:00",
	],

	OPTIONS: {
		status_auta: [
			"",
			"prišlo",
			"prišlo má čas",
			"je na linke",
			"hotové - nezaplatené",
			"vyplatené",
			"nedostavil sa",
		],
		zakaznik: [
			"AGROVAT PS",
			"Arriva",
			"Ato Trans",
			"Balogh",
			"Balogh Adrián",
			"Balogh Tomi",
			"Barbieriková",
			"Best drive",
			"Brantner",
			"Brezík",
			"Camionservis",
			"Cápa",
			"Cargo Expres",
			"Danáč Zoli",
			"Delta",
			"Dosyma",
			"Druga",
			"Farkaš",
			"Fejes",
			"Franko",
			"Galbavý",
			"Grosse Vehne",
			"Gubo",
			"Habrman",
			"Hyundai",
			"Illés Erik",
			"JT Auto",
			"Juhás",
			"Kajan",
			"Kálazi",
			"Karas Tibor",
			"Kele",
			"Kočiš Viktor",
			"Kováč",
			"Krásny",
			"Kucharovič",
			"Lakatoš",
			"LB Trans",
			"Levický",
			"Liad",
			"Limestone",
			"Lubušký",
			"M Logistika",
			"M+Z Trans",
			"Maci",
			"Mandík DPD",
			"Moto Duo",
			"Nap",
			"Németh",
			"Onyx",
			"Ozorai",
			"Paremax",
			"Polák",
			"Poľno Sme",
			"Poľnohospodár",
			"Porsche",
			"Prospect",
			"Quick Trans",
			"Sedlár",
			"SH Tuning",
			"Solár",
			"Speedy Servis",
			"Šalgó",
			"Šimon",
			"Štefánik",
			"Tamper",
			"Teplan",
			"Terravia",
			"Tomat",
			"Tomata",
			"Tóth",
			"TPD transport",
			"Tuhársky",
			"Varga Dubník",
			"Varga Zsolt",
			"Vladap",
			"Vodárne",
			"Volvo",
			"Vrábel",
			"Yssel logistic",
			"Zacharides",
			"Komjatice",
		],
		zakaznik_kategoria: ["", "U", "T", "V", "I"],
		typ_kontroly: [
			"TK",
			"EK",
			"TK + EK",
			"TK opak",
			"EK opak",
			"TK opak + EK opak",
			"Admin",
		],
		platba: ["", "H", "K", "FA"],
		vysledok_tk: ["", "S", "D", "N"],
		vysledok_ek: ["", "S", "D", "N"],
	},

	STATUS_COLOR: {
		"": "row-default",
		prišlo: "row-prislo",
		"prišlo má čas": "row-ma-cas",
		"je na linke": "row-na-linke",
		"hotové - nezaplatené": "row-hotove",
		vyplatené: "row-vyplatene",
		"nedostavil sa": "row-nedostavil",
	},

	STATUS_COLOR_NEW: {
		"": "#ffffff",
		prišlo: "#1cc88a",
		"prišlo má čas": "#36b9cc",
		"je na linke": "#f6c23e",
		"hotové - nezaplatené": "#f57f7f",
		vyplatené: "#d6d6d6",
		"nedostavil sa": "#b881fc",
		refresh: "#ffff00",
	},
}
