<template>
<v-col 
    :lg="computeCols"
    cols="12"
    :class="{'d-none': show_only != null && show_only != link_props.table_name}">
    <v-card :loading="loading">
        <v-app-bar
        flat
        height= "40px"
        color="transparent"
        @click="toggleTable"
        >
            <v-toolbar-title>
                <strong>
                    {{ link_props.title }} ({{ count }}) 
                </strong>
            </v-toolbar-title>
        
            <v-spacer />

            <v-toolbar-title v-show="isSuperAdmin">
                <div>
                    <strong :style="{backgroundColor: delayColor, border: '1px solid black'}" class="px-2">
                        {{ getDelay }}
                    </strong>
                </div>
            </v-toolbar-title>

        </v-app-bar>

        <v-divider/>

        <v-card-text>
            <TempTable :table="link_props.table_name" :ws_data="ws_data" :delay="delay" :count="count" @changeDelay="changeDelay" @setCount="setCount"/>
        </v-card-text>

    </v-card>
</v-col>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import TempTable from './TempTable'

export default {
    name: "TempTableCard",

    data(){
        return{
            loading: false,
            count: 0,
            delay: 0,

            time: '',
        }
    },

    props: ["link_props", "ws_data"],

    components: {
        TempTable
    },

    computed:{
        ...mapGetters(["show_only", "group", "isSuperAdmin"]),

        computeCols(){
            if(this.show_only == null){
                return 6
            }else{
                return 12
            }
        },

        getDelay(){
            if(this.delay <= 0){
                return "Sklz - 0 min."
            }else{
                return "Sklz - " + this.delay + " min."
            }
        },

        delayColor(){
            if(this.delay < 15){
                return "LimeGreen"
            }else if(15 <= this.delay && this.delay < 30){
                return "orange"
            }else{
                return  "red"
            }
            
        },
    },

    methods:{
        ...mapActions(["changeShowOnly"]),

        changeLoading(value){
            this.loading = value
        },

        changeDelay(delay){
            this.delay = delay
        },

        toggleTable(){
            if (this.show_only != this.link_props.table_name){
                this.changeShowOnly(this.link_props.table_name)
            }else{
                this.changeShowOnly(null)
            }
        },

        setCount(count){
            this.count = count
        },

        changeDelayBy60sec(){
            if(this.delay > 0){
                this.delay += 1
            }
        }
    },

    mounted(){
        setInterval(this.changeDelayBy60sec, 60_000)
    },
}
</script>

<style scoped>
</style>