<template>
<v-col cols="12">
    <v-card class="bottom-border mb-1">
        <v-app-bar
        flat
        dense
        color="transparent"
        >
            
            <v-col class="d-none d-md-inline">
                <!-- POZNAMKY -->
                <v-text-field
                    dense
                    label="Poznámky"
                    full-width
                    hide-details
                    class="mb-2"
                    :loading="poznamky_loading"
                    @change="poznamkyChanged"
                    :value="showPoznamka"
                    :readonly="!isAdmin">
                </v-text-field>
        
            </v-col>

            <!-- DATE BUTTONS -->
            <v-col>
            <v-toolbar-title class="headline text-center">
                
                <v-btn @click="handleChangeDayBy(-1)" color="primary" icon x-large> <v-icon large>mdi-chevron-left</v-icon> </v-btn>

                    <v-btn @click="show_dialog = true" large text class="date-btn">
                        <span class="date-top">{{date_string}}</span>
                        
                        <span class="date-bot">{{whichDay}}</span>
                    </v-btn>
                <v-btn @click="handleChangeDayBy(1)" color="primary" icon x-large> <v-icon large>mdi-chevron-right</v-icon> </v-btn>
                
            </v-toolbar-title>
            </v-col>

            <!-- MOJE BUTTONS -->
            <v-col align="right" class="d-none d-md-inline"> <!-- v-if="!isAdmin" cols="1" offset="2" -->
                
                <v-btn v-if="!isAdmin" @click="handleSelfRecords" class="btn" color="primary">{{ showMyBtn }}</v-btn>

                    <!--  :offset="isAdmin ? 3: 0"  cols="1" -->
                <v-btn @click="handleSetDate(new Date())" class="btn" color="primary">DNES</v-btn>
            </v-col>

        </v-app-bar>

        <!-- IF smaller -->
        <v-app-bar
            flat
            dense
            color="transparent"
            class="d-md-none"
            >
            
            <v-col col="3" sm="6">
                <!-- POZNAMKY -->
                <v-text-field
                    dense
                    label="Poznámky"
                    full-width
                    hide-details
                    class="mb-2"
                    :loading="poznamky_loading"
                    @change="poznamkyChanged"
                    :value="showPoznamka"
                    :readonly="!isAdmin">
                </v-text-field>
        
            </v-col>

            <!-- MOJE BUTTONS -->
            <v-col col="9" sm="6" align="right"> <!-- v-if="!isAdmin" cols="1" offset="2" -->
                
                <v-btn v-if="!isAdmin" @click="handleSelfRecords" class="btn" color="primary">{{ showMyBtn }}</v-btn>

                    <!--  :offset="isAdmin ? 3: 0"  cols="1" -->
                <v-btn @click="handleSetDate(new Date())" class="btn" color="primary">DNES</v-btn>
            </v-col>

        </v-app-bar>

        <v-divider/>

        <v-card-actions>
            <v-row dense>
                <!-- FILTERS -->
                <v-col cols="4">
                    <v-select
                        v-model="status_selected"
                        :items="status_items.slice(1, status_items.length)"
                        label="status auta"
                        clearable
                        dense
                        outlined
                        value="" 
                        hide-details
                        :menu-props="{offsetY: true}"
                        @change="handleSelectChanged('status_auta', $event)"
                        ></v-select>
                </v-col>
                <v-col v-if="isAdmin" cols="4">
                    <v-select
                        v-model="technici_selected"
                        :items="technici.slice(1, technici.length)"
                        label="technik"
                        clearable
                        dense
                        outlined
                        value="" 
                        hide-details
                        :menu-props="{offsetY: true, maxHeight: 'auto'}"
                        @change="handleSelectChanged('technik', $event)"
                        ></v-select>
                </v-col>
                <v-col v-if="isAdmin" cols="4">
                    <v-select
                        v-model="zakaznik_selected"
                        :items="zakaznik_items.slice(1, zakaznik_items.length)"
                        label="zákazník"
                        clearable
                        dense
                        outlined
                        value="" 
                        hide-details
                        :menu-props="{offsetY: true}"
                        @change="handleSelectChanged('zakaznik_kategoria', $event)"
                        ></v-select>
                </v-col>
            </v-row>
        </v-card-actions>

        <v-divider/>

    </v-card>

    <!-- DATE PICKER -->
    <v-dialog
        v-model="show_dialog"
        max-width="400px"
        width="370px"
        close-delay="5"
    >
        <v-date-picker
            v-model="self_date"
            scrollable
            elevation="10"
            width="100%"
            @click:date="handleDatePicked"
            @dblclick:date="handleDatePicked"
            :first-day-of-week="1"
            locale="sk-SK"
        >
        </v-date-picker>
    </v-dialog>
</v-col>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import axios from 'axios'

import config from '@/data/config.js'
import {poznamkyByValue, poznamky} from '@/data/api.js'

export default {
    name: "DateNavigator",

    data(){
        return{
            dni: ['nedeľa', 'pondelok', 'utorok', 'streda', 'štvrtok', 'piatok', 'sobota'],
            thisDay: "",

            status_items: [],
            status_selected: null,

            technici_selected: null,

            zakaznik_items: [],
            zakaznik_selected: null,

            show_dialog: false,
            self_date: new Date().toISOString().slice(0,10),

            poznamky_loading: false,
            poznamka: "",
            poznamka_id: -1
        }
    },

    methods:{
        ...mapActions(['initialDate', 'handleChangeDayBy', 'handleSetDate',
                     'changeSelfRecords', 'changeFilter']),

        setStatus(){
            this.status_items = config.OPTIONS.status_auta
        },
        setZakaznik(){
            this.zakaznik_items = config.OPTIONS.zakaznik_kategoria
        },

        async poznamkyChanged(value){
            this.poznamka = value
            // create
            if(this.poznamka_id === -1){
                await axios.post(poznamky(),
                    {
                        datum: this.self_date,
                        poznamka: value
                    },
                    {
                        headers:{
                            "Authorization" : "Token " + this.$store.getters.token,
                            'Content-Type' : 'application/json',
                        },
                    },  
                ).then(response => {
                    console.log(response.data)
                    this.poznamka_id = response.data.id
                }).catch(error => {
                    console.log(error)
                })
            } // update
            else{
                await axios.put(poznamkyByValue(this.poznamka_id),
                    {
                        poznamka: value
                    },
                    {
                        headers:{
                            "Authorization" : "Token " + this.$store.getters.token,
                            'Content-Type' : 'application/json',
                        },
                    },  
                ).then(response =>{
                    console.log(response.data)
                }).catch(error => {
                    console.log(error)
                })
 
            }
        },

        async poznamkyGet(){
            this.poznamky_loading = true

            await axios.get(poznamkyByValue(this.self_date), {
              headers:{
                "Authorization" : "Token " + this.$store.getters.token
              }
            }).then(response =>{
                if (response.data.id == -1){
                    this.poznamka = ""
                    
                }else{
                    this.poznamka = response.data.poznamka
                }
                
                this.poznamka_id = response.data.id
            })

            this.poznamky_loading = false
        },

        handleDatePicked(value){
            // hide dialog
            this.show_dialog = false
            
            let new_date = new Date(value)

            this.handleSetDate(new_date)
        },

        handleSelfRecords(){
            this.status_selected   = null
            this.technici_selected = null
            this.zakaznik_selected = null

            this.changeFilter({})
            this.changeSelfRecords()
        },

        handleSelectChanged(column, value){
            if(column === "status_auta"){
                this.zakaznik_selected = null
                this.technici_selected = null

            }else if(column === "technik"){
                this.status_selected = null
                this.zakaznik_selected = null   

            }else if(column === "zakaznik_kategoria"){
                this.status_selected = null
                this.technici_selected = null
            }

            // Zmeniť tlačítko na ukáž moje
            if(this.show_self_records){
                this.changeSelfRecords()
            }

            this.changeFilter({column, value})
        },
    },

    computed: {
        ...mapGetters(['date', 'date_string', 'show_self_records', 'isAdmin', 'technici']),

        showMyBtn(){
            if(this.show_self_records){
                return "Ukáž všetko"
            }else{
                return "Ukáž moje"
            }
        },
        showPoznamka(){
            return this.poznamka
        },

        whichDay(){
            return this.thisDay
        }
    },
    created(){
        
        this.initialDate()

        this.thisDay = this.dni[this.date.getDay()]

        this.setStatus()
        this.setZakaznik()
    },
    watch: {
        // on date change -> load notes
        date_string(){
            // set date for date picker
            // GMT !!
            let newDate = new Date(this.date.getTime() - (this.date.getTimezoneOffset() * 60000)).toISOString().slice(0,10);
            
            this.self_date = newDate
            this.thisDay = this.dni[this.date.getDay()]

            this.poznamkyGet()
        }
    }
}
</script>

<style scoped>
.bottom-border{
    border-bottom: 3px solid #1976D2;
}
.btn{
    width: 125px;
    margin-inline: 3px;
}

.date-btn{
    font-size: 1.3rem;
    font-weight: 410;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
}

.date-top{
    margin-bottom: 15px;
}
.date-bot{
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    margin-top: 21px;
    position: absolute;
}

</style>